import React, { useEffect, useState } from "react"
import Seo from "../core/Seo"
import { Content, Section } from "../core/LayoutComponents"

const EnterpriseDemoPage = () => {
  const [iframeHeight, setIframeHeight] = useState(1200)

  // TODO: dynamically set the iframe height for form
  // useEffect(() => {
  //   // get the iframe content height
  //   const iframe = document.querySelector("iframe[name=btap_zoho_iframe]")
  //   const iframeContent = iframe.contentWindow.document.body.scrollHeight
  //   setIframeHeight(iframeContent)
  // }, [])

  return (
    <Section>
      <Seo title="We Sparkle • Enterprise Inquiries" includeZohoTracking />
      <Content>
        <h1>Enterprise Inquiries</h1>
      </Content>

      <iframe
        frameBorder="0"
        allowtransparency="true"
        style={{
          height: iframeHeight + "px",
          width: "100%",
          border: "none",
        }}
        src="https://forms.zohopublic.com/wesparkleco/form/EnterpriseInquiries/formperma/V-w4DkK3ZiUit0XY5-cvdZ0Kx0p6Zutbt1SadklsIac?utm_source=Social+Media&utm_medium=Social+Media&utm_campaign=November+Social+Post"
        name="EnterpriseInquiries_zoho_iframe"
      ></iframe>
    </Section>
  )
}

export default EnterpriseDemoPage
